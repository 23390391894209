
.e-dialog .e-dlg-header {
    padding: 0;
    margin: 0;
    font-size: 20px;
    font-weight: bold;
    height: 30px;
}

/*
.e-dialog .e-dlg-content {
    color: red;
    height: 300px;
    font-size: 16px;
    font-weight: normal;
    line-height: normal;
}
*/

/* resize icon */
.e-dialog .e-south-east::before, .e-dialog .e-south-west::before {
    content: '\f047';
}
.e-dialog .e-resize-handle {
    font: normal normal normal 14px/1 FontAwesome;
}

/* Close icon */
.e-dialog .e-btn .e-btn-icon.e-icon-dlg-close {
    font-size: 12px;
    /*color: red;*/
}

/* Footer buttons */
.e-btn.e-flat.e-primary, .e-css.e-btn.e-flat.e-primary {
    width: 100px;
    min-height: 40px;
    background-color: #0870d8;
    color: white;
    border-radius: 25px;
    font-size: 16px;
    text-transform: none;
}

.e-btn.e-flat, .e-css.e-btn.e-flat {
    width: 100px;
    min-height: 40px;
    background-color: darkgray;
    color: black;
    border-radius: 25px;
    font-size: 16px;
    text-transform: none;
}

.e-btn.e-flat.e-primary:hover, .e-css.e-btn.e-flat.e-primary:hover {
    background-color: #0870d8;
    color: white;
    filter: brightness(120%);
}

.e-btn.e-flat:hover, .e-css.e-btn.e-flat {
    background-color: lightgray;
    color: grey;
    transition: 0.1s;
}